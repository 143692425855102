<template>
  <div>
    <!--导航栏--->
    <div  style="z-index: 10;position: fixed;left: 0;right: 0;"> <van-nav-bar title="商品详情" left-arrow @click-left="$router.back(-1)" /></div>
    <!--导航栏--->
    <div style="height: 50px;"></div>
    <!-- 图片 -->
    <div class="111">
      <img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt="">
    </div>
    <!-- 底部 -->
    <div>
      <van-cell is-link @click="showPopup">展示弹出层</van-cell>
      <van-popup v-model="show" closeable close-icon="close" position="bottom" :style="{ height: '60%' }">
        <div>
          <div style="padding: 20px;text-align: center;font-weight: bold;">上架到小店</div>
          <div> <van-card num="2" price="2.00" desc="描述信息" title="商品标题" thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"/>
          </div>
        </div>
        <div>
          <div style="padding: 10px;">推荐语</div>
          <div class="language">
            <div>请输入推荐语</div>
            <div>0/30</div>
          </div>
        </div>
        <div>
          <div style="padding: 10px;">加价（%）</div>
          <div class="languages">
            <div style=" height: 44px; border: 1px solid; width: 45%;
            align-items: center;
             background-color: #f3f0f3; border-radius: 5px;">0</div>
            <div>
              <van-button   type="primary" color="#ff6400">加价到最高</van-button>
              <van-button  type="primary" color="#ff6400">不加价</van-button>
            </div>
          </div>
        </div>
      <div @click="$router.push('')">
        <van-goods-action>
          <van-goods-action-button type="warning" color="#ff6400" text="上架到小店" />
        </van-goods-action>
      </div>
       </van-popup>

    </div>
    <!-- 底部 -->
    
  </div>
</template>

<script>
export default {
  data() {
    return {
         show: true,
    };
  },
  created() {},
  mounted() {},
  methods: {
     showPopup() {
      this.show = true;
    },
     onClickLeft() {
    },
  },
  watch: {},
  computed: {},
};
</script>
<style  >
.language{
     height: 44px;
    background-color: #f3f0f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
    margin: 0 10px;
    color: #d6d3d7;
}
.languages{
   display: flex;
    margin: 0 5px;
    justify-content: space-between;
}
.van-button {
  margin-left: 10px;
  border-radius: 5px;
}

</style>